<template>
	<div class="container">

		<Header></Header>

		<!-- <div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			当前位置: 品牌百科 > {{detail==null?'':detail.title}}
		</div> -->
		<div class="i-main wrap clearfix mt-40">
			<div class="article-left fl border-10">
				<div class="article-l1">
					<h2>{{detail==null?'':detail.title}}</h2>
					<div class="wik-left-m1-bottom clearfix">
						<div class="wik-left-m1-bl fl">{{detail==null?'':detail.authorName}}</div>
						<div class="wik-left-m1-br fr">
							<span><img src="../../assets/images/icon-see.png" alt="" />阅读: {{detail==null?0:detail.views}}</span>
							<!-- <span><img src="../../assets/images/icon-pinglun.png" alt="" />评论: 200</span> -->
							<span><img src="../../assets/images/icon-like.png" alt="" />喜欢: {{detail==null?0:detail.likes}}</span>
						</div>
					</div>
				</div>
				
				<div class="article-l2 mt-40 mb-35">
					<p>{{detail==null?'':detail.articleSummary}}</p>
				</div>
				
				<div class="article-l3">
					<img :src="imgUrl+detail.images.split(',')[0]" alt="" v-if="detail!=null" />
					<div class="article-l3-con" id="content"  v-html="detail==null?'':detail.content"></div>
				</div>
				
				<div class="article-l4 mt-40">
					<div class="article-l4-title">
						<span>免责声明</span>
					</div>
					<p class="article-l4-p">特别声明: 以上作品内容(包括在内的视频、图片或音频)为自媒体与内容原创用户上传并发布，本平台仅提供信息存储空间服务。</p>
					<div class="article-l4-zanbox">
						<div class="article-l4-zan" @click="zanBtn">
							<span></span>
							<p>{{zanNum}}</p>
							<transition name="el-zoom-in-center">
							    <i v-if="numShow">+1</i>
							</transition>
						</div>
						
					</div>
				</div>
				
				<div class="article-pinglun mt-40">
					<h3 class="article-pl-tit">评论<span></span></h3>
					
					<div class="article-pl-con">
						
						<div class="article-pl-group">
							<div class="article-pl-img">
								<img src="../../assets/images/art-pl-img_03.png" alt="" />
							</div>
							<div class="article-pl-right">
								<div class="article-pl-box">
									<p>请 <a href="#">登录</a> 后评论</p>
								</div>
							</div>
						</div><!-- article-pl-group end -->
						
						<!-- <div class="article-pl-group">
							<div class="article-pl-img">
								<img src="../../assets/images/art-pl-img_07.png" alt="" />
							</div>
							<div class="article-pl-right">
								<div class="article-pl-info">
									<h4>蓝鲸</h4>
									<p>作词:王自如?</p>
									<div class="wik-left-m1-bottom clearfix">
										<div class="wik-left-m1-bl fl">4小时前  山东</div>
										<div class="wik-left-m1-br fr">
											<span><img src="../../assets/images/icon-huifu.png" alt="" />回复</span>
											<span><img src="../../assets/images/icon-zan.png" alt="" />2</span>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div class="article-pl-group">
							<div class="article-pl-img">
								<img src="../../assets/images/art-pl-img_10.png" alt="" />
							</div>
							<div class="article-pl-right">
								<div class="article-pl-info">
									<h4>海滨初夏夜</h4>
									<p>不知道什么样得人才会卖给了，这文化也太让人无法接受了</p>
									<div class="wik-left-m1-bottom clearfix">
										<div class="wik-left-m1-bl fl">5小时前  天津</div>
										<div class="wik-left-m1-br fr">
											<span><img src="../../assets/images/icon-huifu.png" alt="" />回复</span>
											<span><img src="../../assets/images/icon-zan.png" alt="" />1</span>
										</div>
									</div>
								</div>
								
							</div>
						</div> -->
						
					</div>
				</div><!-- article-pinglun end -->
			</div><!-- article-left end -->
			<div class="article-right fr">
				<div class="article-right-tj">
					<div class="wik-right-tt-tit">相关推荐</div>
				
					<ul class="wik-right-tj-list mt-20">
						<li v-for="(item,i) in tuijian" :key="i" @click="xgtjLink(item)">
							<a href="javascript:void(0)">
								<div class="wik-right-tj-img">
									<img :src="imgUrl+item.images.split(',')[0]" alt="" v-if="item.imagesLayout == 1"/>
									<img :src="imgUrl+item.images" alt="" v-else/>
								</div>
								<div class="wik-right-tj-txt">
									<h4>{{item.title}}</h4>
									<p>{{item.labels}}</p>
								</div>
							</a>
						</li>
					</ul>
				</div><!-- article-right-tj end -->
				<div class="wik-right-tt mt-15">
					<div class="wik-right-tt-tit">热搜榜</div>
					
					<ul class="wik-right-tt-list">
						<li v-for="(item,h) in resou" :key="h"  @click="rsbLink(item)">
							<i>{{h+1}}</i>
							<div class="wik-right-tt-text">
								<a href="javascript:void(0)">{{item.title}}</a><span class="wik-right-tt-hot" v-if='h == 0'>热</span>
							</div>
						</li>
					</ul>
				</div><!-- wik-right-tt end -->
			</div><!-- article-right end -->
		</div><!-- i-main end  -->
		<div class="i-m9-wrap mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
		
	</div>
</template>

<script>
	import {articleDetail,articleRight} from "../../api/wikipedia.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "articleDetail",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				conDate:true,//控制404页面显示
				industryId:'',//接收id
				detail:{},
				tuijian:[],
				resou:[],
				zanNum:0,
				channelName:0,
				numShow:false,
				pageTitle:'品牌之家',
			};
		},
		created() {
			const id = this.$route.query.id; // 获取参数值
			console.log('接收industryId',id); // 输出参数值id
			this.industryId = id
			this.initPage();
		},
		computed: {},
		mounted() {
			document.title = this.pageTitle;
		},
		methods: {
			initPage(){
				this.getDetail()
				this.getTuijian()
				this.getResou()
			},

			//点赞
			zanBtn(){
				this.zanNum = this.zanNum+1
				this.numShow = true
				var that = this
				setTimeout(function(){
					// alert(1111)
					that.numShow = false
				},2000)
			},

			//页面初始数据
			getDetail(){
				articleDetail(this.industryId).then(res=>{
					console.log(res.data.data,'详情');
					this.detail = res.data.data
					// var htmlString = this.detail.content
					// document.getElementById('content').innerHTML = htmlString;
					document.title = res.data.data.pageTitle;
					var num = res.data.data.likes
					if (num==null || num == '') {
						this.zanNum = 0
					} else{
						this.zanNum=res.data.data.likes
					}
				})
			},

			//推荐
			getTuijian(){
				articleRight({
					articleDetailModuleType:2,
					channelName:this.channelName
				}).then(res=>{
					console.log(res.data.rows,'推荐');
					this.tuijian = res.data.rows
				})
			},
			
			//相关推荐跳转
			xgtjLink(item){
				console.log(item.id,'相关推荐跳转');
				this.industryId = Number(item.id)+9999
				this.$router.push({
					path: '/articleDetail',
					query: {
						id: this.industryId,
					},
				});
				this.initPage()
			},
			//热搜榜跳转
			rsbLink(item){
				console.log(item.id,'相关推荐跳转');
				this.industryId = Number(item.id)+9999
				this.$router.push({
					path: '/articleDetail',
					query: {
						id: this.industryId,
					},
				});
				this.initPage()
			},
			//热搜榜
			getResou(){
				articleRight({
					articleDetailModuleType:3,
					channelName:this.channelName
				}).then(res=>{
					console.log(res.data.rows,'热搜');
					this.resou = res.data.rows
				})
			},
			
			
			// 行业排行-更多列表
			moreBtn() {
				this.$router.push({
					path: '/rankingMore/rankingMore'
				});
			},
				
		
		},
	}
</script>

<style scoped>
	@import "../../assets/css/article.css";
</style>